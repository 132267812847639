import React from 'react';
import { Helmet } from 'react-helmet';

function FleetManagement() {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - Fleet Management</title>
                <meta name="description" content="Simba Tracking System synchronizes GPS Tracking device to manage and secure accordingly fleet, guarantee route transportation & logistics efficiency, 
                planning operations, and optimize costs. Our system provides you with the tools to gain a complete visibility of your  position every minute even if it isn’t connected to internet, 
                no need for constantly checking after your drivers" />
                <meta name="keywords" content="GPS Tracking device, Fleet Management, Route transportation & logistics, Cost Optimization " />
            </Helmet>
            <div className="fleet-management-s-1" style={{ background: '#F8FBFF', minHeight: '600px' }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-7 mb-4 mb-sm-0 full-w mt-sm-3" data-aos="zoom-in-up">
                            <h1 className="title-fs-mobile fw-bold lh-base mt-sm-2 mb-4 text-sm-start text-center t-center" style={{ fontSize: '40px' }}>
                                <span style={{ color: '#FF8A00' }}>Our Tracking System </span> synchronizes GPS device ensuring  Fleet Management & Security
                            </h1>
                            <p className="mbottom-60 fs-6 lh-base text-sm-start text-center t-center" style={{ color: '#888' }}>
                                We are aware of the need of  Gps Tracking devices for shipping companies to stay in the head of the game
                            </p>
                            <p className="mbottom-60 fs-6 lh-base text-sm-start text-center t-center" style={{ color: '#888' }}>
                                Thanks to the synchronization of Gps tracking device with Simba Tracking system, you will manage and secure accordingly fleet s, guarantee route transport logistics efficiency, planning operations, and optimize costs
                            </p>
                            <p className="fs-6 lh-base text-sm-start text-center t-center" style={{ color: '#888' }}>
                                With our system, no need for checking up on your  fleet by calling your drivers, with a single click we provide you with the necessary tools to gain a complete visibility of
                                your  position every minute even if it isn’t connected to internet
                            </p>
                        </div>
                        <div className="col-md-5 ms-auto full-w mt-sm-5 mt-3">
                            <img src="/img/banner 1.png" className="img-fluid" alt="Syncronization of gps tracking device with simba tracking system for a complete visibility of
                                 position" data-aos="zoom-in" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="s-section-2 pt-4" style={{ background: '#F7F7F7', minHeight: '300px' }}>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i>  Fleet Management</h2>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Route Review  </h2>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="700">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Unauthorized Starting / Moving outside designated areas Notification</h3>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 " data-aos="fade-right" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Live Tracking </h2>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i>  Engine Performance</h2>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Driving Mode </h2>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 " data-aos="fade-right" data-aos-duration="700">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Accurate & Updated Truck Information </h3>
                        </div>
                        <div className="col-md-4 " data-aos="zoom-in" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Waste Management </h2>
                        </div>
                        <div className="col-md-4 " data-aos="fade-left" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Driver ID  </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="integrated-products my-5">
                <div className="container">
                    <div className="rect p-sm-3 p-sm-1 p-3">
                        <h4 className="fs-2 lh-base fw-bold text-center primary-color p-2 p-sm-0">
                            Integrated Products
                        </h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6 text-center"  data-aos="fade-right" data-aos-duration="600">
                            <div className="box mb-2">
                                <img src="/img/gps-tracker.png" className="img-fluid" alt="Gps Tracker device" />
                            </div>
                            <h2 className="primary-color">Gps Tracker</h2>
                            <h6>Price : <span className="primary-color fw-bold">30 $</span></h6>
                            <button className="btn btn-default text-white w-100" style={{ background: '#FF9F30', fontSize: '20px' }}>Buy</button>
                        </div>
                        <div className="col-md-6 text-center" data-aos="fade-left" data-aos-duration="600">
                            <div className="box mb-2">
                                <img src="/img/can.jpg" className="img-fluid " alt="can device" />
                            </div>
                            <h2 className="primary-color">Can</h2>
                            <h6>Price : <span className="primary-color fw-bold">30 $</span></h6>
                            <button className="btn btn-default text-white w-100" style={{ background: '#FF9F30', fontSize: '20px' }}>Buy</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features my-5">
                <div className="container text-center">
                    <h4 className="title-fs-mobile fw-bold lh-base mt-sm-5 pt-sm-3 text-center" style={{ fontSize: '40px' }}><span style={{ color: '#FF8A00' }}>System Access</span> Features</h4>
                    <div className="line"></div>
                    <div className="row mt-5">
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/map.png" className="w-50 my-5" alt="Real Time Map View" srcset="" />
                            <h2>Live Map View</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/71-719611_checklist-png-transparent-picture-checklist-yellow-png-download.png" style={{ width: '51%' }} className="my-4" alt="" srcset="" />
                            <h3>Speed, Kilometrage Reports</h3>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/engine-xxl.png" style={{ width: '60%' }} className="my-4" alt="Engine Behavior analysis" srcset="" />
                            <h2> Engine Performance</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/184-1849607_executive-dashboard-icon-orange-pink-analytics-transparent-dashboard.png" style={{ width: '75%' }} className="my-4" alt="Real Time Statistics" srcset="" />
                            <h3>Real Time Statistics</h3>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/8-86900_long-road-png-road-png.png" className="my-5" alt="Route" srcset="" />
                            <h2>Route Planning</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto px-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/lack-of-visibility-of-fuel-consumption.png" style={{ width: '70%' }} className="my-3" alt="Fuel consumption tracking" srcset="" />
                            <h2>Fuel Consumption</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FleetManagement;
