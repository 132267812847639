export const MenuItems = [
    {
        itemName: 'How it works',
        url: '/how-it-works',
        cName: 'nav-link'
    },
    {
        itemName: 'Pricelist',
        url: '/pricelist',
        cName: 'nav-link'
    },
    // {
    //     itemName: 'Contact Us',
    //     url: '#contact',
    //     cName: 'nav-link'
    // },
    {
        itemName: 'Sign Up',
        url: '/sign-up',
        cName: 'nav-link'
    },
    {
        itemName: 'Sign In',
        url: '/sign-in',
        cName: 'nav-link'
    },

]