import React from 'react'
import { Helmet } from 'react-helmet';

function DriverManagement() {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - Driver Management</title>
                <meta name="description" content="Simba Tracking software synchronizes videomatic solutions for optimal driver management, driving style monitoring, 
                efficiency, safety, and behavior evaluation.with our system driver management and work performance are hightly visible and controled "/>
                <meta name="keywords" content="Driver Management, Videomatic solutions, Simba Tracking Software" />
            </Helmet>
            <div className="driver-section-s-1 pt-sm-5 pt-3" style={{ minHeight: '500px', background: '#F8FBFF' }}>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 full-w mt-sm-2 ">
                            <div className="d-flex flex-sm-row flex-column align-items-center">
                                <img src="/img/dsktp 2.png" className="img-fluid w-50 me-sm-2" alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                                <img src="/img/3969905 1.png" className="img-fluid w-50" alt="Fleet Driver" data-aos="zoom-in-down" />
                            </div>
                        </div>
                        <div className="col-md-6 mt-sm-0 mt-5 text-sm-start text-center full-w m-top" data-aos="fade-up">
                            <h1 className="fs-3 fw-bold lh-base mb-4 title-fs-mobile"><span style={{ color: '#FF8A00' }}>Driver</span> Management</h1>
                            <p className="lead lh-base fs-6">
                                As a company decision maker, with one click and through Simba Trucking System, you will be able to receive drivers information, encourage economical safe driving, and professional drivers in your team and across your fleet
                            </p>
                            <p className="lead lh-base fs-6">
                                We synchronize videomatic solutions with Simba Tracking system to measure and monitor drivers driving style, efficiency, safety, and behavior
                            </p>
                            <p className="lead lh-base fs-6">
                                These solutions not only manage and measure driver work performance, ID identification, route tracking, and rules respect but also help
                                improving their behavior and retain efficient employees
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="viedomatic-section" data-aos="zoom-in-down">
                <div className="container">
                    <div className="row">
                        <div className="box p-3 mt-5">
                            <div className="row">
                                <div className="col-md-6 full-w text-center">
                                    <h3 className="fs-2 lh-base fw-bold  pt-3 mb-4 text-sm-start" style={{ color: '#FF8A00' }}>Our Videomatics Solutions</h3>
                                    <ul className="my-4 text-start">
                                        <li className="fs-6 lh-base" style={{ color: '#000', fontWeight: '500' }}>
                                            <h5><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Full HD Cameras</h5>
                                        </li>
                                        <li className="fs-6 lh-base" style={{ color: '#000', fontWeight: '500' }}>
                                            <h5><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Real-time HD image of driver’s view -170° angle</h5>
                                        </li>
                                        <li className="fs-6 lh-base" style={{ color: '#000', fontWeight: '500' }}>
                                            <h5>
                                                <i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Access live video feed and driver data
                                            </h5>
                                        </li>
                                        <li className="fs-6 lh-base" style={{ color: '#000', fontWeight: '500' }}>
                                            <h5>
                                                <i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Instant notification via 3G/4G
                                            </h5>
                                        </li>
                                        <li className="fs-6 lh-base" style={{ color: '#000', fontWeight: '500' }}>
                                            <h5><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Live impact alerting including: location/speed/event footage</h5>
                                        </li>
                                    </ul>
                                    <p className="fs-6 lh-base my-3 text-sm-start">
                                        Simba Trucking advanced fleet management solutions utilize the latest and most accurate GPS vehicle tracking technology
                                        incorporated with videomatic solutions for our clients satisfaction
                                    </p>
                                    <button className="btn btn-default text-white px-5 mt-3 fs-4" style={{ background: '#FF9F30' }}>Acquire Now</button>
                                </div>
                                <div className="col-md-6 mt-sm-5 mt-5 full-w m-top">
                                    <img src="/img/videomatic.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features my-5">
                <div className="container text-center">
                    <h4 className="title-fs-mobile fw-bold lh-base mt-sm-5 pt-sm-3 text-center" style={{ fontSize: '40px' }}><span style={{ color: '#FF8A00' }}>System Access</span> Features</h4>
                    <div className="line"></div>
                    <div className="row mt-5">
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/data.png" className="my-5" alt="" srcset="" />
                            <h2 >Data Transfer</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/punctuality-issues.png" style={{ width: '65%' }} className="my-4 pb-1" alt="" srcset="" />
                            <h3>Driver Performance Analysis</h3>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto px-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/71-719611_checklist-png-transparent-picture-checklist-yellow-png-download.png" style={{ width: '60%' }} className="my-3" alt="" srcset="" />
                            <h2>Driver Reports</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/383-3830776_animation-mobile-png-transparent-png.png" style={{ width: '70%' }} className="my-3" alt="" srcset="" />
                            <h2>Driver App</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/report3.png" style={{ width: '75%' }} className="mb-4 mt-4" alt="" srcset="" />
                            <h3>Safety Reports</h3>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto px-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/950809.png" style={{ width: '65%' }} className="my-4 pb-1" alt="" srcset="" />
                            <h3>Unsave Driving Alerts</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriverManagement
