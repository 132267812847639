export const DropdownItems = [
    {
        // /system-solutions/
        ItemName:'Fleet Management',
        url: '/system-solutions/fleet-management-software',
        cName: 'nav-link'
    },
    {
        ItemName:'Cargo Tracking',
        url: '/system-solutions/cargo-tracking',
        cName: 'nav-link'
    },
    {
        ItemName:'Driver Management',
        url: '/system-solutions/driver-management',
        cName: 'nav-link'
    }
]