import React from 'react';
import { Link } from 'react-router-dom';
import ScrollButton from '../../ScrollButton';
import './Footer.css';

function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <ScrollButton />
                    <div className="row">
                        <div className="col-md-4 full-w">
                            <img src="/img/newlogo.png" className="logo mt-5 mb-4" alt="Simba Trucking Logo" />
                            <p className="desc">
                                Simba Tracking is a subsidiary of Simba Solution, a UK based company dedicated to digital solutions
                                for logistics and freight forwarding industries
                            </p>
                            <button className="btn btn-warning text-white py-2 px-5">Try a demo</button>
                            <div className="social-links mt-4">
                                <i className="fab fa-facebook fa-2x me-3 primary-color"></i>
                                <i className="fab fa-twitter fa-2x me-3 primary-color"></i>
                                <i className="fab fa-linkedin fa-2x primary-color"></i>
                            </div>
                        </div>
                        <div className="col-md-3 ms-auto pt-3 mt-sm-5 mt-3 full-w mtop-5">
                            <h3 className="title mb-2">Menu</h3>
                            <ul className="p-0">
                                <li className="mb-2">
                                    <Link to='/'>Home</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/system-solutions/fleet-management-software">Fleet Management</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/system-solutions/cargo-tracking">Cargo Tracking</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/system-solutions/driver-management">Driver Management</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/how-it-works'>How It Works</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/pricelist">Pricelist</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/sign-up">Sign Up</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/sign-in">Sign In</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 ms-auto mt-sm-5 pt-sm-4 full-w mtop-30" id="contact">
                            <h3 className="title">Contact Us</h3>
                            <p className="desc">
                                Give us a call or send us an email and we will get back to you as soon possible
                            </p>
                            <ul className="p-0">
                                <li className="mb-3"><i className="fas fa-home me-1 primary-color"></i> Simba Solution Limitied 71-75 Shelton Street Covert Garden, London WC2h 9JQUTR: 43133 27262</li>
                                <li className="mb-3"><i className="fas fa-envelope me-1 primary-color"></i> simba-tracking@gmail.com</li>
                                <li><i className="fas fa-phone-alt me-1 primary-color"></i> +442037692827</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyrights text-center py-2">
                <p className="lh-base m-2 text-white fs-5">
                    &copy; Copyright {new Date().getFullYear()} Simba Tracking
                </p>
            </div>
        </>
    )
}

export default Footer;
