import React from 'react';
import { Helmet } from 'react-helmet';
function SignIn() {

    return (
        <>
            <Helmet>
                <title>Simba Tracking - Sign In</title>
            </Helmet>
            <div className="container mt-5 text-center" style={{ minHeight: '750px' }}>
                <div className="row">
                    <div className="col-md-10 mx-auto mt-5" data-aos="fade-up">
                        <h4 className="primary-color">Sign In</h4>
                        <form className="my-form mt-sm-5 mt-3">
                            <div className="form-group mb-2">
                                <input type="text" placeholder="Username" name="name" className="form-control w-sm-75" />
                            </div>
                            <div className="form-group mb-2">
                                <input type="password" placeholder="Password" name="password" className="form-control w-sm-75" />
                            </div>
                            <button className="btn btn-default text-white w-sm-75 w-100 mt-2 mb-sm-0 mb-5 p-3" style={{ background: '#FF9F30' }}>Log In</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn;
