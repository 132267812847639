import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/HallItWork.css';

const NewH = () => {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - How it works</title>
                <meta name="description" content="How it works : To explore Simba Tracking Software, you can register and get access
                to the system free features. The following phase will consist of buying extra instances to utilize all 
                system modules according to your needs  " />
                <meta name="keywords" content="How it works, Software free features" />
            </Helmet>
            <div className="howitallworks-s-1">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-6 mt-sm-5 mb-4 mb-sm-0 pt-sm-5 full-w" data-aos="fade-up">
                            <h1 className="title-fs-mobile fw-bold lh-base mt-sm-5 pt-sm-5 text-sm-start text-center t-center" style={{ fontSize: '45px' }}><span style={{ color: '#FF8A00' }}>How it</span> works</h1>
                        </div>
                        <div className="col-md-6 full-w">
                            <img src="/img/banner 1.png" className="img-fluid" alt="" data-aos="zoom-in" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="howitallworks-s-2 my-sm-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 full-w" data-aos="zoom-in-down">
                            <div className="stepper-wrapper my-5">
                                <div className="stepper-item completed mb-3 mb-sm-0" >
                                    <div className="step-counter fs-1 text-white">1</div>
                                    <h4 className="step-name fs-5 fw-bold primary-color text-center">
                                        Register and Get <strong>Free one Instance</strong>
                                    </h4>
                                </div>
                                <div className="stepper-item active mb-3 mb-sm-0">
                                    <div className="step-counter fs-1 text-white">2</div>
                                    <h4 className="step-name fs-5 fw-bold primary-color text-center">
                                        Start Exploring Our System
                                    </h4>
                                </div>
                                <div className="stepper-item active mb-3 mb-sm-0">
                                    <div className="step-counter fs-1 text-white">3</div>
                                    <h4 className="step-name fs-5 fw-bold primary-color text-center">
                                        Buy Extra Instances
                                    </h4>
                                </div>
                                <div className="stepper-item active mb-3 mb-sm-0">
                                    <div className="step-counter fs-1 text-white">4</div>
                                    <h4 className="step-name fs-5 fw-bold primary-color text-center">
                                        Simba Tracking System Full Exploitation
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 mt-sm-5 full-w">
                            <img src="/img/dsktp 2.png" className="img-fluid " alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="howitallworks-s-3 pt-3">
                <div className="container">
                    <h3 className="fs-3 fw-bold lh-base my-3 text-center"><span style={{ color: '#FF8A00' }}>Simba Tracking Software </span> Is Easy As....</h3>
                    <div className="line"></div>
                    <div className="row mt-5">
                        <div className="col-md text-center mb-4 mb-sm-0">
                            <div className="circle mx-auto" style={{ background: '#FF8A00' }} data-aos="fade-down">
                                <div className="mini-circle"><span className="fs-3 fw-bold text-white">1</span></div>
                                <img src="/img/map 1.png" className="img-fluid w-50" style={{ paddingTop: '0px' }} alt="Route Geofencing in the System" />
                            </div>
                            <h2 className="fs-3 lh-sm" data-aos="fade-up">
                                Route Geofencing <br /> in the System
                            </h2>
                        </div>
                        <div className="col-md text-center mb-4 mb-sm-0">
                            <div className="circle mx-auto" style={{ border: '4px solid #373737' }} data-aos="fade-down">
                                <div className="mini-circle" style={{ background: '#373737' }}><span className="fs-3 fw-bold text-white">2</span></div>
                                <img src="/img/track 1.png" className="img-fluid w-50" style={{ paddingTop: '0px' }} alt="" />
                            </div>
                            <h2 className="fs-3 lh-sm" data-aos="fade-up">
                                Cargo Transportation <br /> Dashboard Overview
                            </h2>
                        </div>
                        <div className="col-md text-center mb-4 mb-sm-0">
                            <div className="circle mx-auto" style={{ border: '4px solid #373737' }} data-aos="fade-down">
                                <div className="mini-circle" style={{ background: '#373737' }}><span className="fs-3 fw-bold text-white">3</span></div>
                                <img src="/img/calendar 1.png" className="img-fluid w-50" style={{ paddingTop: '0px' }} alt="" />
                            </div>
                            <h2 className="fs-3 lh-sm" data-aos="fade-up">
                                Route Transportation & Logistics <br /> Management
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default NewH;