import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Price.css';

function PriceList() {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - Price List</title>
                <meta name="description" content="Get access to all payment modalities throught Simba Tracking price list
                and according to your needs, choose the most convenient payment option" />
                <meta name="keywords" content="Price List, Payment option, Simba Tracking software "  / >
            </Helmet>
            <div className="pl-section-1 mb-5" style={{ background: '#F8FBFF', minHeight: '520px' }}>
                <div className="container">
                    <div className="row pt-5 text-sm-start text-center">
                        <div className="col-md-6 mt-sm-5 mt-3 pt-sm-5 full-w" data-aos="fade-up">
                            <h1 className="fw-bold lh-base mt-sm-5 pt-sm-5 title-fs-mobile t-center" style={{ fontSize: '45px', color: '#FF8A00' }}>Price List</h1>
                        </div>
                        <div className="col-md-6 full-w">
                            <img src="/img/Pricelist 1.png" className="img-fluid mt-5" alt="Price list figure" data-aos="zoom-in-up" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pl-section-2 my-sm-5 " style={{ minHeight: '700px', overflow : 'hidden' }}>
                <div className="container">
                    <h3 className="fs-2 lh-base fw-bold text-center">Pricelist</h3>
                    <div className="line"></div>
                    <div className="row my-5">
                        <div className="col-md-3 mb-sm-0 mb-4 width-50" data-aos="fade-right" data-aos-duration="1000" >
                            <div className="box p-2">
                                <h2 className="fs-4 lh-base" style={{ color: '#FF9F30' }}>Free</h2>
                                <div className="mini-box text-center p-2">
                                    <p className="fs-6 fw-bold lh-sm p-3" style={{ color: '#000' }}>One Instance <br /> No Charges</p>
                                </div>
                                <div className="mini-block my-4">
                                    <div className="my-3">
                                        Access Free Standard Features
                                    </div>
                                    {/* <i className="fas fa-plus"></i> */}
                                    <div className="my-2 p-3">
                                        Buy Additional Instances
                                    </div>
                                </div>
                                <div className="" style={{ marginTop: '265px' }}>
                                    <button className="btn btn-outline-default w-100 mb-2" style={{ border: ' 1px solid #FF9F30', color: '#FF9F30' }}>Free of Charges</button>
                                    <button className="btn btn-default w-100 text-white" style={{ background: '#FF9F30' }}>Sign Up </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-sm-0 mb-4 width-50"data-aos="fade-right" data-aos-duration="1000">
                            <div className="box p-2">
                                <h3 className="fs-4 lh-base" style={{ color: '#FF9F30' }}>Pay Per Instance</h3>
                                <div className="mini-box text-center p-2">
                                    <p className="fs-6 fw-bold lh-sm pt-2" style={{ color: '#000' }}>Instance price  <br /> 25 $ / monthly</p>
                                </div>
                                <div className="mini-block my-4">
                                    <div className="my-3">
                                        Access Free Standard Features for all your instances
                                    </div>
                                    <div className="my-2 p-3">
                                        Buy Additional Instances
                                    </div>
                                </div>
                                <div style={{ marginTop: '240px' }}>
                                    <button className="btn btn-outline-default w-100 mb-2" style={{ border: ' 1px solid #FF9F30', color: '#FF9F30' }}>Annual plan 20 $ / Instance </button>
                                    <button className="btn btn-default w-100 text-white" style={{ background: '#FF9F30' }}>Sign Up </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-sm-0 mb-4 width-50 m-top" data-aos="fade-left" data-aos-duration="1000">
                            <div className="box p-2">
                                <h2 className="fs-4 lh-base" style={{ color: '#FF9F30' }}>Premium</h2>
                                <div className="mini-box text-center p-2">
                                    <p className="fs-6 fw-bold lh-sm" style={{ color: '#000' }}>Instrance Price <br /> 35 $ / monthly</p>
                                </div>
                                <div className="mini-block my-4">
                                    <div className="my-3">Access Free Features for all your Instances</div>
                                    <div className="my-3">Videomatic service</div>
                                    <div className="my-3">Cargo GPS Tracking Service</div>
                                    <div className="my-3">
                                        Reports / Analysis
                                    </div>
                                </div>
                                <div style={{ marginTop: '145px' }}>
                                    <button className="btn btn-outline-default w-100 mb-2" style={{ border: ' 1px solid #FF9F30', color: '#FF9F30' }}>Annual plan 29 $ / Instance</button>
                                    <button className="btn btn-default w-100 text-white" style={{ background: '#FF9F30' }}>Sign Up </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-sm-0 mb-4 width-50 m-top" data-aos="fade-left" data-aos-duration="1000">
                            <div className="box p-2">
                                <h2 className="fs-4 lh-base" style={{ color: '#FF9F30' }}>Entreprise</h2>
                                <div className="mini-box text-center p-2">
                                    <p className="fs-6 fw-bold lh-sm" style={{ color: '#000' }}>More than 50 Vehicles</p>
                                </div>
                                <div className="mini-block my-4">
                                    <div className="my-3">
                                        Access All Simba Tracking System Features
                                    </div>
                                    <div className="my-3">
                                        50 or plus Activated Instances
                                    </div>
                                    <div className="my-3">Customization</div>
                                    <div className="my-3">Videomatic service</div>
                                    <div className="my-3">Cargo GPS Tracking Service</div>
                                    <div className="my-3">
                                        Reports / Analysis
                                    </div>
                                </div>
                                <div style={{ marginTop: '33px' }}>
                                    <button className="btn btn-outline-default w-100 mb-2" style={{ border: ' 1px solid #FF9F30', color: '#FF9F30' }}>Request a price quote</button>
                                    <button className="btn btn-default w-100 text-white" style={{ background: '#FF9F30' }}>Contact Us </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <button class="buy-btn btn btn-default ms-sm-2  text-white fs-3"><span>Buy Now</span></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceList;
