import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../../css/Home.css';

function Home() {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - Cargo Tracking, Fleet and Driver Management Software</title>
                <meta name="description" content="Simba Tracking software is destinated for Cargo Tracking, fleet and Drivers management all in one single Software. 
                    The System dashboard provides you with a Real-time visibility and control of all your Route Transportation and Logistics Tracking in One Screen" />
                <meta name="keywords" content="Fleet Management, Driver Management, and Cargo Tracking System " />
            </Helmet>
            <div className="home-section-1 pt-sm-5">
                <div className="container">
                    <div className="row mt-sm-5">
                        <div className="col-md-7 full-w mb-sm-0 mb-5  text-sm-start text-center mt-sm-2 mt-5" data-aos="fade-down">
                            <h1 className="lh-base fw-bold mb-sm-4 mb-3 primary-color title-fs-mobile" style={{ fontSize: '42px' }}>
                                Cargo Tracking, Fleet <span className="fw-bold text-color"> and <span className="primary-color">Driver Management</span>, All in one single Software</span>
                            </h1>
                            <p className="mb-3">
                                Simba Tracking software is destinated for the management of fleet, Cargo, and Drivers all in one single platform.
                                The System dashboard provides you with a Real-time visibility and control of all your Route Transportation and Logistics Tracking in One Screen
                            </p>
                            <button className="btn btn-default text-white">
                                <span>Try for free</span>
                            </button>
                        </div>
                        <div className="col-md-5 full-w">
                            <img src="/img/dsktp 2.png" className="img-fluid" alt="Simba Tracking System Dashboard" data-aos="fade-up" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section-2 my-5" style={{ background: '#fff', minHeight: '450px' }}>
                <div className="container">
                    <div className="row">
                        <h4 className="fs-2 fw-bold lh-base my-sm-5 mt-2 mb-4 text-center">
                            <span className="primary-color">Are You</span> Fed Up With.....
                        </h4>
                        <div className="col-md-2 width-50" data-aos="zoom-in" data-aos-duration="700">
                            <div className="box" >
                                <img src="/img/shipment.png" className="img-fluid" style={{width : '90%'}} alt="Lack of shipment Control and visibility" />
                            </div>
                            <h3 className="fs-6 fw-bold lh-base mt-3 text-center primary-color"> Lack of  shipment Control and visibility</h3>
                        </div>
                        <div className="col-md-2 ms-sm-auto width-50" data-aos="zoom-in" data-aos-duration="700">
                            <div className="box" style={{ background: '#FF8A00' }} >
                                <img src="/img/lack-fo-control_1.png" className="img-fluid w-50" alt="Fleet Management problems" />
                            </div>
                            <h3 className="fs-6 fw-bold lh-base mt-3 text-center">Fleet Management problems</h3>
                        </div>
                        <div className="col-md-2 ms-sm-auto width-50" data-aos="zoom-in" data-aos-duration="700">
                            <div className="box">
                                <img src="/img/lack-of-drivers-supervision.png" className="img-fluid w-50" alt="Lack  of Drivers Supervision" />
                            </div>
                            <h3 className="fs-6 fw-bold lh-base mt-3 text-center"> Lack of Drivers Supervision</h3>
                        </div>
                        <div className="col-md-2 ms-sm-auto width-50" data-aos="zoom-in" data-aos-duration="700">
                            <div className="box">
                                <img src="/img/lack-of-visibility-of-fuel-consumption.png" className="img-fluid w-50" alt="Lack of Visibility of Fuel Consumption" />
                            </div>
                            <h3 className="fs-6 fw-bold lh-base mt-3 text-center">
                                Lack of Visibility of Fuel Consumption
                            </h3>
                        </div>
                        <div className="col-md-2 ms-sm-auto width-50 " data-aos="zoom-in" data-aos-duration="500">
                            <div className="box">
                                <img src="/img/performance-and-monitoring.png" className="img-fluid w-50" alt="Performance and Monitoring" />
                            </div>
                            <h3 className="fs-6 fw-bold lh-base mt-3 text-center">
                                Performance and Monitoring
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section-3 mb-5 mb-sm-0 pt-sm-4" style={{overflow : 'hidden'}} >
                <div className="container">
                    <h4 className="fw-bold lh-base fs-2 text-center mb-2 pt-3">
                        <span className="primary-color">Solutions With</span> Simba Tracking System
                    </h4>
                    <div className="line mb-3"></div>
                    <div className="row">
                        <div className="col-md-4 p-sm-4 p-2 text-sm-start text-center"data-aos="fade-right" data-aos-duration="500">
                            <div className="box" style={{ borderRadius: '0px 111px' }}>
                                <h2 className="fs-3 lh-base mb-4 mb-sm-3">
                                    <Link to="/system-solutions/fleet-management-software" className="text-dark">Fleet Management</Link>
                                </h2>
                                <p className="fs-6 lh-base mb-4 mb-sm-2 m-top mt-3 ">
                                    Thanks to our GPS tracking system, we guarantee you costs optimization and efficiency increase of all your route logistics and planning operations
                                </p>
                                <img src="/img/pngtree-vector-cartoon-red-freight-car-png-image_495200.png" className="img-fluid" alt="Simba Tracking System Dashboard" />
                            </div>
                        </div>
                        <div className="col-md-4  p-sm-4 p-2 text-sm-start text-center" data-aos="zoom-in" data-aos-duration="500">
                            <div className="box" style={{ borderRadius: '0px 111px' }}>
                                <h2 className="fs-3 lh-base mb-4 mb-sm-3">
                                    <Link to="/system-solutions/cargo-tracking" className="text-dark">Cargo Tracking</Link>
                                </h2>
                                <p className="fs-6 lh-base mb-5 mb-sm-3 m-top mt-3 ">
                                    Simba tracking system provides owners with Real time visibility during cargo transportation
                                    thus all shipments will be under control
                                </p>
                                <img src="/img/Untitled-1 2.png" className=" " alt="Shipment Tracking" />
                            </div>
                        </div>
                        <div className="col-md-4  p-sm-4 p-2 text-sm-start text-center" data-aos="fade-left" data-aos-duration="500">
                            <div className="box" style={{ borderRadius: '0px 111px' }}>
                                <h2 className="fs-3 lh-base mb-4 mb-sm-3">
                                    <Link to="/system-solutions/driver-management" className="text-dark">Driver Management</Link>
                                </h2>
                                <p className="fs-6 lh-base mb-4 mb-sm-3 m-top mt-3 ">
                                    We synchronize videomatic solutions with Simba Tracking System to measure and monitor drivers driving style, performance,
                                    and behavior for work efficiency
                                </p>
                                <img src="/img/3969905 1.png" className="img-fluid mt-3" alt="Driver Behavior Management" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section-5" style={{ background: '#fff', minHeight: '700px', overflow : 'hidden' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-sm-start text-center full-w mt-5 pt-5" data-aos="fade-right" data-aos-duration="500">
                            <h3 className="fs-2 lh-base fw-bold pt-sm-5 mb-sm-5 mb-4 mt-3">
                                <span className="primary-color">Our System Provides You </span>Control & Visibility
                            </h3>
                            <p className="lh-base fs-6">
                                Our GPS Tracking devices can be implemented in all phases of your Route Transportation & Logistics
                                ensuring a complete Visibility and Management for the following
                            </p>
                            <ul className="p-0">
                                <li><i className="fas fa-check primary-color"></i> Fleet Management</li>
                                <li><i className="fas fa-check primary-color"></i> Cargo Tracking</li>
                                <li><i className="fas fa-check primary-color"></i> Driver Management</li>
                            </ul>
                            <p className="fs-6 lh-base">
                                Simba Tracking advanced software utilizes the latest and most accurate technologies
                                for an all integrated dashboard of above services
                            </p>
                        </div>
                        <div className="col-md-6 full-w">
                            <img src="/img/REV06-06.png" data-aos="fade-left" data-aos-duration="500" alt="visibility and control" />
                        </div>
                    </div>
                    <div className="track-input py-5" data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <h2 className="fs-1 lh-base fw-bold pt-5 mb-sm-5 mb-4 text-capitalize">
                                        Live <span className="primary-color">Tracking</span> 
                                    </h2>
                                </div>
                                <div className="col-md-8 mt-sm-3">
                                    <h4 className="fs-5 text-capitalize lh-base fw-bold">Enter Your GPS device ID and Visualize fleet & cargo current location</h4>
                                    <form className="d-flex">
                                        <input type="text" className="form-control" name="track-id" id="track-id" placeholder="Track ID" />
                                        <button className="btn btn-default">Track</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section-6 py-5 mb-4 mb-sm-0" style={{ background : '#F8FBFF',minHeight: '400px' }}>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-6 mt-sm-5 pt-sm-3 mb-4 mb-sm-0" data-aos="fade-up">
                            <h4 className="fs-2 lh-base mb-sm-5 mb-4">We provide you with one <strong>Free Instance</strong> <i className="ms-4 fas fa-arrow-right primary-color"></i></h4>
                            <button className="btn btn-default text-white py-2 px-5 fs-3 mt-sm-5" style={{ background: '#FF8A00' }}>Sign Up</button>
                        </div>
                        <div className="col-md-6 mt-5" data-aos="zoom-in-up">
                            <div className="d-sm-flex flex-sm-row flex-column align-items-center">
                                <img src="/img/dsktp 2.png" className="img-fluid" style={{ width: '45%' }} alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                                <img src="/img/gps-step1.svg" className="img-fluid" style={{ width: '50%' }} alt="traking GPS Localization" />
                            </div>
                            <p className="fs-4 primary-color mt-3 mt-sm-0">Activated Client Access to Simba Tracking System Free Features</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-3" style={{ background: '#F7F7F7', minHeight: '360px' }}>
                <div className="container text-sm-center text-start ">
                    <h4 className="fw-bold lh-base fs-2 text-center mb-2">
                        You will be able to utilize ...
                    </h4>
                    <div className="line"></div>
                    <div className="row pt-sm-5 mt-4 mt-sm-2">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Customers creation</h5>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Drivers creation / Assignment </h5>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Route / Geofencing</h5>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Jobs creation</h5>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> One Activated Instance</h5>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="700">
                            <h5 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Identification </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Live Tracking </h2>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Delivery Options  </h2>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="700">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Delivery Items </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dowload-app p-sm-2" style={{ background: '#fff', minHeight: '300px', overflow : 'hidden' }}>
                <div className="container">
                    <div className="row">
                        <h3 className="fs-2 mb-2 fw-bold lh-base text-center pt-3">Download <span className="primary-color">Simba Tracking</span> Mobile App</h3>
                        <div className="line"></div>
                        <div className="mt-sm-5 d-sm-flex flex-sm-row flex-column justify-content-around align-items-center">
                            <img src="/img/app-store.png" data-aos="fade-right" data-aos-duration="500" className="img-fluid my-3 my-sm-0 mr-20" alt="Simba Tracking mobile app ios" srcset="" />
                            <img src="/img/google-play.png" data-aos="fade-left" data-aos-duration="500" className="img-fluid mb-4 mb-sm-0" alt="Simba Tracking mobile app android" srcset="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;
