import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItems } from './MenuItems';
import { DropdownItems } from './DropdownItems';
import './Navbar.css';

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const [navbar, setNavbar] = useState(false)

    const changeNavbarBg = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeNavbarBg)

    return (
        <>
            <nav className={navbar ? 'navbar navbar-expand-lg fixed-top top-0 active' : 'navbar navbar-expand-lg sticky-top top-0'}>
                <div className="container">
                    <Link to="/" className="navbar-brand me-0" onClick={closeMobileMenu}>
                        <img srcSet="img/newlogo.png" alt="Simba Tracking Logo" className=" " />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>

                    <ul className={click ? 'navbar-nav active' : 'navbar-nav'}>
                        <li className="nav-item">
                            <Link to="/" onClick={closeMobileMenu} className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                System solutions
                            </Link>
                            <ul className="dropdown-menu p-1 p-sm-0" aria-labelledby="navbarDropdown">
                                {DropdownItems.map((item, index) => {
                                    return (
                                        <li className="nav-item" key={index}>
                                            <Link to={item.url} onClick={closeMobileMenu} className={item.cName}>{item.ItemName}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                        {MenuItems.map((item, index) => {
                            return (
                                <li className="nav-item" key={index} onClick={closeMobileMenu}>
                                    <Link to={item.url} className={item.cName}>{item.itemName}</Link>
                                </li>
                            )
                        })}
                        <button className="btn btn-default ms-sm-2 p-sm-2 p-4 text-white" style={{ background: '#F44D16' }} onClick={closeMobileMenu}><i className="fas fa-download"></i> Dowload App</button>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar;