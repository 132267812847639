import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet';
function SignUp() {
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_heyfk5s', 'template_yljn04j', e.target, 'user_0Kc222Ur2ZzXo7ahMawuc')
            .then((result) => {
                console.log(result.text);
                setMessage('Thank you. Your message is sent successfuly');
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <>
            <Helmet>
                <title>Simba Tracking - Sign Up</title>
            </Helmet>
            <div className="container mt-5 text-center" style={{ minHeight: '750px' }}>
                <div className="row">
                    <div className="col-md-10 mx-auto mt-5" data-aos="fade-up">
                        <h4 className="primary-color">Sign Up</h4>
                        <form className="my-form mt-sm-5 mt-3" onSubmit={sendEmail}>
                            {message && (<div className="alert alert-success">{message}</div>)}
                            <div className="form-group mb-2">
                                <input type="text" placeholder="Name" name="name" className="form-control w-sm-75" />
                            </div>
                            <div className="form-group mb-2">
                                <input type="email" placeholder="Email" name="email" className="form-control w-sm-75" />
                            </div>
                            <div className="form-group mb-2">
                                <input type="text" placeholder="Phone Number" name="phone" className="form-control w-sm-75" />
                            </div>
                            <div className="form-group">
                                <textarea name="message" id="" placeholder="Message" cols="30" rows="5" className="form-control w-sm-75" />
                            </div>
                            <button className="btn btn-default text-white w-sm-75 w-100 mt-2 mb-sm-0 mb-5 p-3" style={{ background: '#FF9F30' }}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp;
