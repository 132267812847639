import React from 'react'
import { Helmet } from 'react-helmet';

function CargoTracking() {
    return (
        <>
            <Helmet>
                <title>Simba Tracking - Cargo Tracking</title>
                <meta name="description" content="Simba tracking software provides you efficient cargo tracking for your shipment transportation management and 
                security. The system guarantees a complete overview of your shipment route, travel history, and transhipment " />
                <meta name="keywords" content="Cargo Tracking, Shipment transportation management, transhipment" />
            </Helmet>
            <div className="cargo-s-1" style={{ background: '#F8FBFF', minHeight: '500px' }}>
                <div className="container">
                    <div className="row mb-5 text-sm-start text-center">
                        <div className="col-md-6 mt-sm-4 full-w pt-sm-5" data-aos="fade-up">
                            <h1 className="fs-1 fw-bold lh-base t-center title-fs-mobile mt-sm-5 mt-4 pt-2"><span style={{ color: '#FF8A00' }}>Cargo</span> Tracking </h1>
                            <p className="mbottom-60 fs-6 lh-base text-sm-start text-center t-center">
                                As experts in supply chain and logistics fields, we have become aware of the market need
                                for a system that provides a complete visibility of Route logistics including
                                shipping containers location, cargo tracking & placement, and travel history
                            </p>
                            <p className="mbottom-60 fs-6 lh-base text-sm-start text-center t-center" style={{ color: '#888' }}>
                                Simba tracking software makes it all possible, It allows you with Real time view during your cargo
                                transportation thus all your shipments will be secure and under control
                            </p>
                            <p className="fs-6 lh-base text-sm-start text-center t-center" style={{ color: '#888' }}>
                                We guarantee you with one click and through simba trucking system a complete management and
                                vision of the following
                            </p>
                        </div>
                        <div className="col-md-6 mt-sm-5 full-w pt-sm-5">
                            <div className="d-flex flex-sm-row flex-column align-items-center">
                                <img src="/img/pngtree-vector-cartoon-red-freight-car-png-image_495200.png" className="img-fluid w-50" alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                                <img src="/img/dsktp 2.png" className="img-fluid w-50" alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ background: '#F7F7F7', minHeight: '300px' }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="600">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Transhipment</h2>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="600">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Safe Transport</h2>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="600">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Route optimization</h2>
                        </div>
                    </div>
                    <div className="row row my-sm-4 my-4">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="600">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Minimal cargo damage with a recommended driving style </h3>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="600">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Real time notification once the truck leave destined routes</h3>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="600">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Travel History</h2>
                        </div>
                    </div>
                    <div className="row row my-sm-4 my-4">
                        <div className="col-md-4" data-aos="fade-right" data-aos-duration="600">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Identification of additional cargo load introduced in the vehicle </h3>
                        </div>
                        <div className="col-md-4" data-aos="zoom-in" data-aos-duration="600">
                            <h3 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Uninterrupted Data Record </h3>
                        </div>
                        <div className="col-md-4" data-aos="fade-left" data-aos-duration="600">
                            <h2 className="fs-5 fw-bold lh-sm"><i className="fas fa-check" style={{ color: '#FF8A00' }}></i> Route Review </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="integrated-products my-5">
                <div className="container">
                    <div className="rect p-sm-3 p-sm-1 p-3">
                        <h4 className="fs-2 lh-base fw-bold text-center primary-color p-2 p-sm-0">
                            Integrated Products
                        </h4>
                    </div>
                    <div className="row">
                        <div className="row mt-5">
                            <div className="col-md-4 text-center" data-aos="fade-right" data-aos-duration="600">
                                <div className="box mb-2">
                                    <img src="/img/gps-tracker.png" className="img-fluid" alt="Gps Tracker device" />
                                </div>
                                <h2 className="primary-color">Gps Tracker</h2>
                                <h6>Price : <span className="primary-color fw-bold">30 $</span></h6>
                                <button className="btn btn-default text-white w-100" style={{ background: '#FF9F30', fontSize: '20px' }}>Buy</button>
                            </div>
                            <div className="col-md-4 text-center" data-aos="zoom-in" data-aos-duration="600">
                                <div className="box mb-2">
                                    <img src="/img/lite.jpg" style={{ width: '100%' }} className="img-fluid" alt="Lite device" />
                                </div>
                                <h2 className="primary-color">LITE</h2>
                                <h6>Price : <span className="primary-color fw-bold">30 $</span></h6>
                                <button className="btn btn-default text-white w-100" style={{ background: '#FF9F30', fontSize: '20px' }}>Buy</button>
                            </div>
                            <div className="col-md-4 text-center" data-aos="fade-left" data-aos-duration="600">
                                <div className="box mb-2">
                                    <img src="/img/cargo.jpg" className="img-fluid w-75" alt="Cargo device" />
                                </div>
                                <h2 className="primary-color">Cargo</h2>
                                <h6>Price : <span className="primary-color fw-bold">30 $</span></h6>
                                <button className="btn btn-default text-white w-100" style={{ background: '#FF9F30', fontSize: '20px' }}>Buy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features my-5">
                <div className="container text-center">
                    <h2 className="title-fs-mobile fw-bold lh-base mt-sm-5 pt-sm-3 text-center" style={{ fontSize: '40px' }}><span style={{ color: '#FF8A00' }}>System Access</span> Features</h2>
                    <div className="line"></div>
                    <div className="row mt-5">
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/images.png" style={{ width: '70%' }} className="my-3" alt="" srcset="" />
                            <h2>Geofencing</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/Electronic-log-book 1.png" className="my-3" style={{ width: '50%' }} alt="" />
                            <h2>Elog</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/8-86900_long-road-png-road-png.png" style={{ width: '' }} className="my-5" alt="" srcset="" />
                            <h2>Route Planning</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/pngtree-vector-cartoon-red-freight-car-png-image_495200.png" className="img-fluid w-75 my-3" alt="Simba Tracking System Dashboard" data-aos="zoom-in-down" />
                            <h2>Cargo</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50"data-aos="zoom-in">
                            <img src="/img/man-loading-boxes-van-flat-illustration-courier-delivery-guy-carrying-cardboard-container-packages-parcels-truck-warehouse-159328785.jpg" style={{ width: '90%' }} className="my-4 pb-4" alt="" srcset="" />
                            <h2>Pick Up</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto px-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/route-optimization-2.png" className="my-4" style={{ width: '90%' }} alt="" srcset="" />
                            <h3>Route Optimization</h3>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/mang.png" style={{ width: '90%' }} className="my-4 pb-sm-3" alt="" srcset="" />
                            <h2>Capacity Management</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto p-2 mb-3 width-50" data-aos="zoom-in">
                            <img src="/img/71-719611_checklist-png-transparent-picture-checklist-yellow-png-download.png" style={{ width: '60%' }} className="my-3" alt="" srcset="" />
                            <h2>Reports</h2>
                        </div>
                        <div className="f-box col-md-3 me-sm-auto px-2 mb-3 width-50"data-aos="zoom-in">
                            <img src="/img/mobapp.png" style={{ width: '40%' }} className="my-3 pb-sm-3" alt="" srcset="" />
                            <h2>Mobile App</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CargoTracking
