
import React, { useEffect } from 'react';
import './css/App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from './components/navbar/NewNavbar';
import ScrollToTop from './ScrollToTop';
import Home from './components/pages/Home';
import Footer from './components/footer/Footer';
import PriceList from './components/pages/PriceList';
import HowItAllWorks from './components/pages/NewHowItAW';
import DriverManagement from './components/pages/DriverManagement';
import TurboCargo from './components/pages/CargoTracking';
import FleetManagement from './components/pages/FleetManagement';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path='/sign-up' component={SignUp}/>
          <Route path='/sign-in' component={SignIn}/>
          <Route path='/system-solutions/fleet-management-software' component={FleetManagement} />
          <Route path='/system-solutions/cargo-tracking' component={TurboCargo} />
          <Route path='/system-solutions/driver-management' component={DriverManagement} />
          <Route path='/pricelist' component={PriceList} />
          <Route path='/how-it-works' component={HowItAllWorks} />
          <Route path='/' exact component={Home} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
